









import { Component, Vue, Prop } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  components: {
    pdf,
  },
})
export default class Covid extends Vue {
  @Prop({ required: true })
  file!: string;

  numPages = 0;
  src: any = null;

  mounted() {
    this.src = pdf.createLoadingTask(`/covid/${this.file}.pdf`);
    this.src.promise.then((file: any) => {
      this.numPages = file.numPages;
    });
  }
}
